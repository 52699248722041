import React from "react";
import styled from "styled-components";

const MyButton = ({ children, ...args }) => {
  return <MyButtonBox {...args}>{children}</MyButtonBox>;
};

export default MyButton;

const MyButtonBox = styled.button`
  * {
    box-sizing: border-box;
  }
  position: relative;
  width: ${(props) => (props.width ? props.width : "auto")};
  margin: ${(props) => (props.margin ? props.margin : "0 0")};
  padding: ${(props) =>
    props.padding ? props.padding : "12px 22px"} !important;
  text-align: center;
  color: ${(props) => props.color};
  font-family: var(--fontFamily);
  font-size: ${(props) => (props.size ? props.size + "px" : "14px")};
  font-style: normal;
  font-weight: ${(props) => (props.weight ? props.weight : "600")};
  line-height: normal;
  border-radius: 8px;
  border: ${(props) =>
    props.border ? "1px solid" + props.border : "transparent"};
  background: ${(props) =>
    props.background ? props.background : "transparent"};
  cursor: pointer;
  transition: all 0.3s linear;

  &:hover {
    background: ${(props) =>
      props.backgroundHover ? props.backgroundHover : props.background};
    color: ${(props) => (props.hoverColor ? props.hoverColor : props.color)};
  }

  .hover_text {
    position: absolute;
    z-index: 999;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
    color: #555;
    font-weight: 500;
    line-height: 1.2;
    width: 200%;
    text-align: center;
    padding: 3px 10px;
    background-color: aliceblue;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease-out, opacity 0.3s ease-out,
    box-shadow 0.3s ease-out;
  }
  &:hover {
    .hover_text {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
  }
`;
