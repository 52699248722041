import styled from "styled-components";
import { MIN_LENGTH } from "../../../../../constants";

export const Wrapper = styled.div`
  background: var(--bgImage);

  .container {
    max-width: ${MIN_LENGTH}px;
    margin: auto;
    padding: 30px 0;
    padding-bottom: 50px;
  }

  .profile-image-upload {
    display: inline-block;
    padding: 12px 22px;
    border-radius: 8px;
    border: 1px solid #208667;
    background-color: #208667;
    color: #fff;
    font-weight: 500;
    transition: all 0.3s linear;
    cursor: pointer;
  }

  .profile-image-upload:hover {
    background-color: #175845;
  }

  .img-box {
    width: 100px;
    height: 100px;
    overflow: hidden;
  }

  .img-loader {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
  }

  img {
    width: 100px;
    height: 100px;
    border-radius: 65px;
  }

  .create-top {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
  }

  .form-item {
    width: 100%;
    position: relative;
  }
  .eye-icon {
    position: absolute;
    height: 45px;
    width: 45px;
    bottom: 0px;
    right: 0px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  h5 {
    color: #208667;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
  }

  a {
    color: rgba(32, 134, 103, 0.5);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
  }

  .create-form {
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.08);
    padding: 20px;
  }

  label {
    display: block;
    color: var(--text-drak, #000);
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  input {
    display: block;
    border-radius: 6px;
    border: 1px solid #208667;
    background: var(--interactive-enabled, #fff);
    padding: 5px 12px;
    width: 320px;
    outline: none;
    height: 30px;
    margin-top: 7px;
  }

  .create-select {
    border-radius: 6px;
    border: 1px solid #208667;
    background: var(--interactive-enabled, #fff);
    padding: 5px 12px;
    width: 348px;
    outline: none;
    height: 40px;
    margin-top: 7px;
  }

  .form-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0;
  }

  .create-bottom {
    display: flex;
    justify-content: flex-start;
  }

  .create-submit {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }

  .form-submit {
    border-radius: 8px;
    background: #208667;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    height: 40px;
    min-width: 120px;
    padding: 0 20px;
  }
`;
