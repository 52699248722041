import { Box } from "@mui/material";
import { FormBox } from "./style";
import ParticipantList from "./components/ParticipantList";
import { AddUser } from "./components";
import { MyButton } from "../../../../../../components";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { API } from "../../../../../../services/api";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function Participant({ setActive, meetingId }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { lng } = useSelector((store) => store.language);

  const {
    data: watcherMembersData,
    refetch: watcherMembersDataRefetch,
    isLoading: watcherMembersDataLoading,
  } = useQuery("membersByMeetingFormResterTrue", async () => {
    const response = await API.getMembersByMeeting({
      meetingId: meetingId,
      fromReestr: false,
    })
      .then((res) => res.data)
      .catch((err) => console.log("Fetch Members Error", err));

    return response;
  });

  const { data: simpleMembersData, isLoading: simpleMembersDataLoading } =
    useQuery("membersByMeetingFormResterFalse", async () => {
      const response = await API.getMembersByMeeting({
        meetingId: meetingId,
        fromReestr: true,
      })
        .then((res) => res.data)
        .catch((err) => console.log("Fetch Members Error", err));

      return response;
    });

  const speakers = useMemo(() => {
    const arr = [];
    if (!!simpleMembersData && !!watcherMembersData) {
      [...simpleMembersData, ...watcherMembersData].forEach((member) => {
        arr.push({
          ...member,
          label: member?.user[lng === "uz" ? `fullName` : `fullName_${lng}`],
        });
      });
    }
    return arr;
  }, [simpleMembersData, watcherMembersData, lng]);

  const nextPage = () => {
    setActive("agenda");
    navigate(`/admin/meetings/create#agenda`);
  };

  const prevPage = () => {
    setActive("general");
    navigate(`/admin/meetings/create#general`);
  };

  return (
    <FormBox>
      <Box className="box">
        <Box className="box-right">
          <Box className="box-left-header" element="ul" component={"ul"}>
            <Box className="box-left-header-item" element="li" component={"li"}>
              №
            </Box>

            <Box
              className="box-left-header-item"
              element="li"
              component={"li"}
              style={{
                textAlign: "left",
                paddingLeft: "30px",
              }}
            >
              {t("participant")}
            </Box>

            <Box className="box-left-header-item" element="li" component={"li"}>
              {t("role")}
            </Box>
          </Box>
          {watcherMembersDataLoading || simpleMembersDataLoading
            ? "loading..."
            : !!speakers && (
                <ParticipantList
                  data={speakers}
                  refetch={watcherMembersDataRefetch}
                />
              )}
        </Box>

        <Box className="box-left">
          <AddUser refetch={watcherMembersDataRefetch} meetingId={meetingId} />
        </Box>
      </Box>

      <div className="form-box-footer">
        <MyButton
          color="#208667"
          background="#FFFFFF"
          border="#208667"
          onClick={prevPage}
        >
          {t("back")}
        </MyButton>
        <MyButton
          color="#FFFFFF"
          background="#208667"
          border="#208667"
          onClick={nextPage}
        >
          {t("next")}
        </MyButton>
      </div>
    </FormBox>
  );
}
export default Participant;
