import { useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  ImageDiv,
  ProtocolBottom,
  ProtocolDiv,
  ProtocolRight,
  ProtocolTop,
} from "./style";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import ReplaySharpIcon from "@mui/icons-material/ReplaySharp";

import image from "../../../../assets/images/chairman.png";
import sign from "../../../../assets/icons/sign.png";
import download from "../../../../assets/icons/download.png";
import SignWithERI from "./SignWithERI";
import {
  DeleteIcon,
  DownIcon,
  EditIcon,
  ProtocolIcon,
  downloadIcon,
} from "../../../../assets/icons";
import { BASE_URL, CHAIRMAN, SECRETARY, SIMPLE } from "../../../../constants";
import { t } from "i18next";
import { useQuery } from "react-query";
import { API } from "../../../../services/api";
import MyButton from "../../../../components/UI/MyButton/MyButton";
import { green } from "@mui/material/colors";
import { useMemo } from "react";
import { toast } from "react-toastify";

const ChairmanProtocol = ({
  setIsProtocol,
  membersData,
  setProtocolLang,
  isEdit,
  setIsEditCompany,
  setIsEditProtocolHtml,
  setIsEditProtocolQRHtml,
  setLang,
                            setSelectedLngs,
                            selectedLngs,
                            meetingInfo
}) => {
  const [open, setOpen] = useState(false);
  const { meetingId } = useParams();
  const user = useSelector((state) => state.userData);
  const { data, refetch, isFetching } = useQuery("signInProtocol", async () => {
    const response = await API.getSignInProtocol({ id: meetingId })
      .then((res) => res.data)
      .catch((err) => console.log(err));

    return response;
  });

  const [isQuorum, setIsQuorum] = useState(false);

  const protocolSignInMember = useMemo(() => {
    let a = 0;
    let b = 0;
    if (!!data) {
      data.forEach((item) => {
        if (item.memberTypeEnum === CHAIRMAN) {
          a += 1;
        } else if (item.memberTypeEnum === SIMPLE) {
          b += 1;
        }
      });
    }

    return { chairman: a, simple: b };
  }, [data]);

  const memberRole = localStorage.getItem("memberRole")
    ? JSON.parse(localStorage.getItem("memberRole"))
    : {};

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const resetProtocol = (lang) => {
    const reset = async () => {
      await API.resetPdfHtml({ meetingId, lang })
        .then((res) => toast.success(lang + " protocol reseted"))
        .catch((err) => console.log(err));
    };
    reset();
  };
  const resetQR = (lang) => {
    const reset = async () => {
      await API.resetQRHtml({ meetingId, lang })
        .then((res) => toast.success(lang + " protocol reseted"))
        .catch((err) => console.log(err));
    };
    reset();
  };


  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      setSelectedLngs((prev) =>
        prev.length < 2 ? [...prev, name] : [prev[1], name]
      );
    } else {
      setSelectedLngs((prev) => prev.filter((lang) => lang !== name));
    }
  };
console.log(meetingInfo)
  return (
    <ProtocolDiv>
      <ImageDiv>
        <img src={image} alt="protocol" />
      </ImageDiv>
      <ProtocolRight>
        <ProtocolTop>
          <h2>{t("userPage.protocol.protokol")}</h2>

          <div className="user-protocol-signin">
            <button
              className="quorum_btn"
              onClick={() => {
                setIsQuorum((prev) => !prev);
              }}
            >
              {isQuorum ? t("quorumAvailable") : t("noQuorum")}
            </button>
            {/* <div className="users">
              <p>
                <span
                  className="user-protocol-signin--text"
                  style={{ paddingLeft: "5px" }}
                >
                  {t("chairMan")}
                </span>
                :{" "}
                <span style={{ paddingLeft: "5px" }}>
                  {protocolSignInMember.chairman}
                </span>
              </p>
              <p className="user-protocol-signin--text_box">
                <span
                  className="user-protocol-signin--text"
                  style={{ paddingRight: "5px" }}
                >
                  {t("companyForm.companyParticipants")}
                </span>
                :{" "}
                <div
                  style={{
                    paddingLeft: "5px",
                    display: "flex",
                    alignItems: "center",
                    gap: "3px",
                  }}
                >
                  <span>{protocolSignInMember.simple}</span> /{" "}
                  <span>{!!membersData && membersData.length}</span>
                </div>
              </p>
            </div> */}
            {isFetching ? (
              <MyButton>
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                  }}
                />
              </MyButton>
            ) : (
              <MyButton onClick={() => refetch()}>
                <ReplaySharpIcon color="success" />
              </MyButton>
            )}
          </div>
        </ProtocolTop>
        <ProtocolBottom>
          {isEdit ? (
            memberRole.memberTypeEnum === SECRETARY && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div className="lang_cards">
                  <label>
                    <input
                      type="checkbox"
                      name="uz"
                      checked={selectedLngs.includes("uz")}
                      onChange={handleCheckboxChange}
                    />
                    UZ
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="en"
                      checked={selectedLngs.includes("en")}
                      onChange={handleCheckboxChange}
                    />
                    EN
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="ru"
                      checked={selectedLngs.includes("ru")}
                      onChange={handleCheckboxChange}
                    />
                    RU
                  </label>
                </div>

                <div className="btns_card">
                  <a
                      href={
                          BASE_URL +
                          "/meeting/generate-pdf?meetingId=" +
                          meetingId +
                          "&lang=" +
                          selectedLngs.reduce((acc, item) => acc + "-" + item)
                      }
                      target="_blank"
                      className="download_btn"
                  >
                    <img src={downloadIcon} alt="download icon"/>

                    <p className="hover_text">{t("downloadDraftProtocol")}</p>
                  </a>
                  <button
                      onClick={() => {
                        setProtocolLang(
                            selectedLngs.reduce((acc, item) => acc + "-" + item)
                        );
                        setIsEditProtocolHtml(true);
                      }}
                      className="edit_btn"
                  >
                    <img src={ProtocolIcon} alt="edit icon"/>
                    <p className="hover_text">{t("editTheProtocol")}</p>
                  </button>
                  <button
                      onClick={() => {
                        setProtocolLang(
                            selectedLngs.reduce((acc, item) => acc + "-" + item)
                        );

                        setIsEditProtocolQRHtml(true);
                      }}
                      className="qr_edit_btn"
                  >
                    QR <img src={ProtocolIcon} alt="edit icon"/>
                    <p className="hover_text">{t("editQrCode")}</p>
                  </button>
                  {/*<button*/}
                  {/*  onClick={() => {*/}
                  {/*    resetProtocol(*/}
                  {/*      selectedLngs.reduce((acc, item) => acc + "-" + item)*/}
                  {/*    );*/}
                  {/*  }}*/}
                  {/*  className="reset_btn"*/}
                  {/*>*/}
                  {/*  <ReplaySharpIcon />*/}
                  {/*  <p className="hover_text">{t("revertOriginalVersion")}</p>*/}
                  {/*</button>*/}
                </div>

                {/*<div*/}
                {/*    className="btns_card"*/}
                {/*    style={{justifyContent: "space-around"}}*/}
                {/*>*/}

                  {/*<button*/}
                  {/*  onClick={() => {*/}
                  {/*    resetQR(*/}
                  {/*      selectedLngs.reduce((acc, item) => acc + "-" + item)*/}
                  {/*    );*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  <ReplaySharpIcon />*/}
                  {/*</button>*/}
                {/*</div>*/}
              </div>
            )
          ) : (
            <>
              {" "}
              <div className="lang_cards">
                <label>
                  <input
                    type="checkbox"
                    name="uz"
                    checked={selectedLngs.includes("uz")}
                    onChange={handleCheckboxChange}
                  />
                  UZ
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="en"
                    checked={selectedLngs.includes("en")}
                    onChange={handleCheckboxChange}
                  />
                  EN
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="ru"
                    checked={selectedLngs.includes("ru")}
                    onChange={handleCheckboxChange}
                  />
                  RU
                </label>
              </div>
              <a
                href={
                  BASE_URL +
                  "/meeting/generate-pdf?meetingId=" +
                  meetingId +
                  "&lang=" +
                  selectedLngs.reduce((acc, item) => acc + "-" + item)
                }
                target="_blank"
              >
                <span>{t("userPage.downloadProtocol")}</span>
                <img src={download} alt="download" />
              </a>
              {meetingInfo.onlyChairmanSign&&memberRole.memberTypeEnum === CHAIRMAN||!meetingInfo.onlyChairmanSign?<button onClick={handleClickOpen}>
                <span>{t("userPage.protocol.protocolSgin")}</span>
                <img src={sign} alt="sign"/>
              </button>:""}
            </>
          )}
        </ProtocolBottom>
        <Dialog open={open} onClose={handleClose}>
          <DialogContent>
            <SignWithERI
              meetingId={meetingId}
              userId={user.id}
              login={user.login}
              handleClose={handleClose}
            />
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </ProtocolRight>
    </ProtocolDiv>
  );
};

export default ChairmanProtocol;
