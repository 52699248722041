import React from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  meetingGetByIDFunc,
  meetingGetCities,
  meetingGetCompaniesFunc,
  updateMeetingFunc,
} from "./queryColbackFunc";
import { Box, Button, CircularProgress, MenuItem, Select } from "@mui/material";
import { Wrapper } from "./EditMeetingStyle";
import routeNames from "../../../../../constants/routeNames";
import { useTranslation } from "react-i18next";
import { editInputText } from "../../../../../utils";
import { useSelector } from "react-redux";
import { useState } from "react";
import EditMeetingGeneral from "./components/EditMeetingGeneral";
import EditMeetingParticipant from "./components/EditMeetingParticipant";
import EditMeetingAgenda from "./components/EditMeetingAgenda";

const changeValue = (setValue, data) => {
  setValue("companyId", data.companyId);
  setValue("companyName_uz", data.companyName_uz);
  setValue("companyName_en", data.companyName_en);
  setValue("companyName_ru", data.companyName_ru);
  setValue("cityId", data.cityId);
  setValue("protocolNumber", data.protocolNumber);
  setValue("address", data.address);
  setValue("description_uz", data.description_uz);
  setValue("description_en", data.description_en);
  setValue("description_ru", data.description_ru);
  setValue("status", data.status);
  setValue("typeEnum", data.typeEnum);
  setValue("startDate", data.startDate.slice(0, data.startDate.length - 1));
};

const EditMeeting = () => {
  const navigation = useNavigate();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { lng } = useSelector((store) => store.language);
  const { meetingId } = useParams();
  const { register, handleSubmit, watch, setValue } = useForm();
  const [active, setActive] = useState("general");

  const { data: companies } = useQuery(
    "getAllCompanies",
    meetingGetCompaniesFunc
  );

  const { data: cities } = useQuery("cities", meetingGetCities);
  const { isLoading: meetingLoading } = useQuery(
    "meetingData",
    async () => await meetingGetByIDFunc(meetingId),
    {
      onSuccess: (data) => {
        if (!!data) {
          changeValue(setValue, data);
        }
      },
    }
  );

  const menuData = [
    { id: 1, label: t("meetingData"), hash: "general" },
    { id: 2, label: t("meetingParticipants"), hash: "participant" },
    { id: 3, label: t("dayIssues"), hash: "agenda" },
  ];

  const changeMenu = (value) => {
    if (meetingId) {
      setActive(value);
      // navigate(`/admin/meetings/create#${value}`);
    }
  };

  const { mutate: updateMeeting, isLoading: updateMeetingLoading } =
    useMutation((props) => updateMeetingFunc({ data: props, navigation, t }));

  const onSubmit = (data) => {
    const city = cities?.find((city) => city?.id === data?.cityId);
    const company = companies?.find(
      (company) => company?.id === data?.companyId
    )?.name_uz;

    const startD = data.startDate;

    const modifiedData = {
      ...data,
      city: city,
      address: editInputText(data.address),
      description: editInputText(data.description),
      companyName: company,
      status: "PENDING",
      protocolNumber: data?.protocolNumber,
      startDate: startD.length === 16 ? startD + ":00.000Z" : startD + ".000Z",
      id: meetingId,
    };

    updateMeeting(modifiedData);
  };

  if (meetingLoading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={"80vh"}
      >
        <CircularProgress
          color="success"
          style={{ width: "100px", height: "100px" }}
        />
      </Box>
    );
  }

  return (
    <Wrapper>
      <div className="container">
        <Box className="create-top">
          <Link to="/admin">{t("home")}</Link>
          <Link to={routeNames.ADMIN_MEETINGS}>{t("meetings")}</Link>
          <h5>{t("routes.addOrEditPage.editMeeting")}</h5>
        </Box>

        <Box className="company-list">
          <Box className="company-left">
            {menuData.map((el) => (
              <button
                onClick={() => changeMenu(el.hash)}
                className={`company-link ${el.hash === active && "active"}`}
              >
                {el.label}
              </button>
            ))}
          </Box>

          <Box className="meeting-right">
            {active === "general" ? (
              <EditMeetingGeneral setActive={setActive} meetingId={meetingId} />
            ) : active === "participant" ? (
              <EditMeetingParticipant
                setActive={setActive}
                meetingId={meetingId}
              />
            ) : active === "agenda" ? (
              <EditMeetingAgenda setActive={setActive} meetingId={meetingId} />
            ) : (
              ""
            )}
          </Box>
        </Box>

        {/* <form onSubmit={handleSubmit(onSubmit)} className="create-form">
          <Box className="form-list" component={"ul"}>
            <Box className="form-item" component={"li"}>
              <label>
                {t("routes.controlPage.clientPage")}
                <Select
                  {...register("companyId", { required: true })}
                  className="create-select"
                  value={watch("companyId") ? watch("companyId") : ""}
                >
                  {companies?.map((status) => (
                    <MenuItem key={status?.id} value={status?.id}>
                      {status[`name_${lng}`]}
                    </MenuItem>
                  ))}
                </Select>
              </label>
              <label>
                {t("cityOrRegion")}
                <Select
                  {...register("cityId", { required: true })}
                  className="create-select"
                  value={watch("cityId") ? watch("cityId") : ""}
                >
                  {cities?.map((cities) => (
                    <MenuItem key={cities?.id} value={cities?.id}>
                      {cities.nameUz}
                    </MenuItem>
                  ))}
                </Select>
              </label>

              <label>
                {t("meetingsList.commentMeeting")}_uz
                <textarea
                  {...register("description_uz")}
                  placeholder={t("companyForm.enter")}
                  style={{ height: "120px" }}
                />
              </label>

              <label>
                {t("meetingsList.commentMeeting")}_ru
                <textarea
                  {...register("description_ru")}
                  placeholder={t("companyForm.enter")}
                  style={{ height: "120px" }}
                />
              </label>

              <label>
                {t("meetingsList.commentMeeting")}_en
                <textarea
                  {...register("description_en")}
                  placeholder={t("companyForm.enter")}
                  style={{ height: "120px" }}
                />
              </label>
            </Box>

            <Box className="form-item" component={"li"}>
              <label>
                {t("idProtocol")} *
                <input
                  {...register("protocolNumber", { required: true })}
                  type="number"
                  required
                  placeholder={t("companyForm.enter")}
                />
              </label>
              <label>
                {t("meetingsList.address")}
                <input
                  {...register("address", { required: true })}
                  type="text"
                  required
                  placeholder={t("companyForm.enter")}
                />
              </label>
              <label>
                {t("meetingsList.startMeeting")}
                <input
                  {...register("startDate", { required: true })}
                  type="datetime-local"
                />
              </label>
            </Box>
          </Box>

          <Box className="create-submit">
            <Button
              onClick={handleSubmit(onSubmit)}
              className="form-submit"
              variant="contained"
              color="success"
            >
              {updateMeetingLoading ? <CircularProgress /> : t("settings")}
            </Button>
            <Button
              color="success"
              className="delete-btn"
              variant="outlined"
              onClick={() => navigation(routeNames.ADMIN_MEETINGS)}
            >
              {t("cancel")}
            </Button>
          </Box>
        </form> */}
      </div>
    </Wrapper>
  );
};

export default EditMeeting;
