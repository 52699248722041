import React, { forwardRef, useEffect } from "react";
import JoditEditor from "jodit-react";
import { useMemo } from "react";

const JoditEditorComponent = forwardRef(
  ({ value, handleChange, name, id }, ref) => {
    const config = useMemo(
      () => ({
        readonly: false,
        placeholder: "Start typings...",
        fullPage: true,
        iframe: true,
        allowTags: "*",
        allowAttributes: "*",
        iframeStyle: `
      body { font-family: Arial; font-size: 14px; color: black; }
      h1, h2, h3 { color: blue; }
      ul { list-style-type: disc; padding-left: 20px; }
      ol { list-style-type: decimal; padding-left: 20px; }
      p { margin: 10px 0; }
    `,
      }),
      [id]
    );

    return (
      <div>
        <JoditEditor
          ref={ref}
          value={value}
          config={config}
          tabIndex={1}
          id={id}
          onBlur={(newContent) => {
            handleChange({
              key: name,
              value: newContent, // Save Jodit editor content to state
            });
          }}
        />
      </div>
    );
  }
);

export default JoditEditorComponent;
