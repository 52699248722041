import React, { useState } from "react";
import ParticipantItem from "../ParticipantItem";
import styled from "styled-components";
import {CHAIRMAN, SECRETARY} from "../../../../../../../../constants";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { deleteMemberFunc } from "../../extra";
import { useMutation } from "react-query";
import DeleteButton from "../DeleteButton/DeleteButton";

function ParticipantList({ data, refetch }) {
  console.log("data==>", data);
  const { lng } = useSelector((store) => store.language);
  return (
    <ParticipantListBox>
      {data
        ?.slice()
          .sort((a, b) =>lng === "uz"
              ? a?.user?.fullName?.localeCompare(b?.user?.fullName)
              : lng === "ru"
                  ? a?.user?.fullNameRu?.localeCompare(b?.user?.fullNameRu)
                  : a?.user?.fullNameEn?.localeCompare(b?.user?.fullNameEn))
        .sort((a, b) =>
          a.memberTypeEnum === CHAIRMAN
            ? -1
            : b.memberTypeEnum === CHAIRMAN
            ? 1
            : 0
        )
        .sort((a, b) =>
          a.memberTypeEnum === SECRETARY
            ? 1
            : b.memberTypeEnum === SECRETARY
            ? -1
            : 0
        )
        .map((el, idx) => (
          <ParticipantItem {...el} key={el.id} idx={idx} refetch={refetch} />
        ))}
    </ParticipantListBox>
  );
}

export default ParticipantList;

const ParticipantListBox = styled.ul`
  position: relative;
  z-index: 1;
  padding-left: 0;
  height: 436px;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #ddf8f0;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #208667;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #208667;
    border-radius: 10px;
  }
`;
