import React, { useState, useEffect } from "react";
import {
  MeetingCard,
  MeetingLists,
  MeetingTopic,
  ShowVotes,
  StyledUsersVotes,
} from "./style";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import UsersList from "./UsersList";
import { API } from "../../../../../../services/api";
import ShowVotedUsers from "./ShowVotedUsers";
import {CHAIRMAN, meetingType, ROLES, SECRETARY} from "../../../../../../constants";
import { BASE_URL } from "../../../../../../constants";
import downloadIcon from "../../../../../../assets/icons/download.png";
const UsersVotes = ({
  agendas,
  members,
  meetingId,
  meetingData,
  meetingInfo,
  memberRole
}) => {
  const { t } = useTranslation();
  const { lng } = useSelector((store) => store.language);
  const [isVoting, setIsVoting] = useState(false);
  const [agenda, setAgenda] = useState(null);
  const [results, setResult] = useState([]);
  const [selectedLngs, setSelectedLngs] = useState(["uz", "en"]);
  const user = useSelector((store) => store.userData);
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      setSelectedLngs((prev) =>
        prev.length < 2 ? [...prev, name] : [prev[1], name]
      );
    } else {
      setSelectedLngs((prev) => prev.filter((lang) => lang !== name));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (meetingId) {
        try {
          const res = await API.getBallotsResultByMeetingId(meetingId);
          setResult(res.data);
        } catch (error) {
          console.error("Error fetching ballot results:", error);
        }
      }
    };
    fetchData();
  }, [isVoting]);

  if (isVoting) {
    return (
      <UsersList
        agenda={agenda}
        setIsVoting={setIsVoting}
        members={members}
        meetingId={meetingId}
        meetingData={meetingData}
      />
    );
  }
  console.log('members =>', members);
  const downloadAll =() => {

    const urls = [];
    for (const member of members) {
      const url =
        BASE_URL +
        "/pdf/ballot?meetingId=" +
        meetingId +
        "&language=" +
        selectedLngs.join("-") +
        "&memberId=" +
        member.id;
        urls.push(url);
    }
    var interval = setInterval(download, 300, urls);
  
    function download(urls) {
  
      var url = urls.pop();

      window.open(url, "_blank");

      if (urls.length == 0) {
        clearInterval(interval);
      }
    }
  };
  

  const memberIds = members.map((member) => member.id);
  console.log(user)
  return (
    <StyledUsersVotes>
      <MeetingLists>
        {agendas?.map((meeting) => (
          <MeetingCard
            key={meeting.id}
            onClick={() => {
              if (
                memberRole?.memberTypeEnum === ROLES.SECRETARY &&
                meetingInfo.meetingType === meetingType.OFFLINE
              ) {
                setAgenda(meeting);
                setIsVoting(true);
              }
            }}
          >
            <MeetingTopic gTopic>{meeting[`subject_${lng}`]}</MeetingTopic>
            {results.map((result) => {
              if (result.agendaId == meeting.id) {
                return (
                  <>
                    <div className="show-votes">
                      <ShowVotes color={"#3EB250"}>
                        {t("userPage.votes.votePositive")}:
                      </ShowVotes>{" "}
                      {memberRole?.memberTypeEnum == SECRETARY || memberRole?.memberTypeEnum == CHAIRMAN ?result.agree.length:result.agree.filter(u=>u.id==user.id ).length} {t("votes")}
                      <ShowVotedUsers users={result.agree} currentUser={user} memberTypeEnum={memberRole?.memberTypeEnum}/>
                    </div>
                    <div className="show-votes">
                      <ShowVotes color={"#FFC700"}>
                        {t("userPage.votes.voteNeutral")}:
                      </ShowVotes>{" "}
                      {memberRole?.memberTypeEnum == SECRETARY || memberRole?.memberTypeEnum == CHAIRMAN ?result.refrain.length:result.refrain.filter(u=>u.id==user.id ).length} {t("votes")}
                      <ShowVotedUsers users={result.refrain} currentUser={user}  memberTypeEnum={memberRole?.memberTypeEnum}/>
                    </div>
                    <div
                      className="show-votes"
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <ShowVotes color={"rgba(255, 0, 0, 0.90)"}>
                        {t("userPage.votes.voteControversy")}:
                      </ShowVotes>{" "}
                      {memberRole?.memberTypeEnum == SECRETARY || memberRole?.memberTypeEnum == CHAIRMAN ?result.against.length:result.against.filter(u=>u.id==user.id ).length} {t("votes")}
                      <ShowVotedUsers users={result.against} currentUser={user} memberTypeEnum={memberRole?.memberTypeEnum}/>
                    </div>
                  </>
                );
              }
            })}
          </MeetingCard>
        ))}
      </MeetingLists>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <div className="lang_cards">
          <label>
            <input
              type="checkbox"
              name="uz"
              checked={selectedLngs.includes("uz")}
              onChange={handleCheckboxChange}
            />
            UZ
          </label>
          <label>
            <input
              type="checkbox"
              name="en"
              checked={selectedLngs.includes("en")}
              onChange={handleCheckboxChange}
            />
            EN
          </label>
          <label>
            <input
              type="checkbox"
              name="ru"
              checked={selectedLngs.includes("ru")}
              onChange={handleCheckboxChange}
            />
            RU
          </label>
        </div>

      {memberRole.memberTypeEnum === 'SECRETARY' ? (
        <div>
          <button onClick={downloadAll} className="download_btn">
            <img src={downloadIcon} alt="download icon" />
          </button>
        </div>
      ) : (
        <div>
          <a
            href={
              BASE_URL +
              "/pdf/ballot?meetingId=" +
              meetingId +
              "&language=" +
              selectedLngs.reduce((acc, item) => acc + "-" + item) +
              "&memberId=" + memberRole.id
            }
            target="_blank"
            className="download_btn"
          >
            <img src={downloadIcon} alt="download icon" />
          </a>
        </div>
      )}
      </div>
    </StyledUsersVotes>
  );
};

export default UsersVotes;
