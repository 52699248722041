import styled from "styled-components";
import { MIN_LENGTH } from "../../../../../constants";

export const Wrapper = styled.div`
  background: var(--bgImage);

  .container {
    max-width: ${MIN_LENGTH}px;
    margin: auto;
    padding: 30px 0;
    padding-bottom: 40px;
  }

  .create-top {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
  }

  h5 {
    color: #208667;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
  }

  a {
    color: rgba(32, 134, 103, 0.5);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
  }

  .create-form {
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.08);
    padding: 20px;
  }

  label {
    display: flex;
    flex-direction: column;
    color: var(--text-drak, #000);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 15px;
  }
  /* input,
  textarea {
    border-radius: 6px;
    border: 1px solid #208667;
    background: var(--interactive-enabled, #fff);
    padding: 10px 12px;
    width: 100%;
    outline: none;
    margin-top: 7px;
    box-sizing: border-box;
  } */

  .create-select {
    border-radius: 6px;
    border: 1px solid #208667;
    background: var(--interactive-enabled, #fff);
    padding: 5px 12px;
    width: 100%;
    outline: none;
    height: 40px;
    margin-top: 7px;
  }

  .form-list {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    margin: 15px 0;
  }

  .create-bottom {
    display: flex;
    justify-content: flex-start;
  }

  .create-submit {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
  }

  .form-item {
    width: 100%;
    position: relative;
  }
  .eye-icon {
    position: absolute;
    height: 45px;
    width: 45px;
    bottom: 0px;
    right: 0px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  /* .form-submit {
    border-radius: 8px;
    background: #208667;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    height: 40px;
  } */

  .company-list {
    display: grid;
    grid-template-columns: 270px minmax(500px, 756px);
    justify-content: space-between;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.08);
    padding: 30px;
  }

  .company-left {
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 12px 15px;
    width: 250px;
    gap: 1px;
    height: 110px;
  }

  .company-link {
    display: block;
    width: 100%;
    padding: 12px 15px;
    color: rgba(93, 133, 121, 0.5);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background-color: #ddf8f0;
    border: none;
    cursor: pointer;
    text-align: start;
  }

  .active {
    position: relative;
    color: #5d8579;
  }

  .active::after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 4px;
    border-radius: 8px 0px;
    background: #208667;
    content: "";
  }
`;
