import { Button, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ProtocolsBody, StyledProtocol } from "./style";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { API } from "../../../../services/api";
import UserProtocol from "./UserProtocol";
import { green } from "@mui/material/colors";
import MyButton from "../../../../components/UI/MyButton/MyButton";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useTranslation } from "react-i18next";
import { editInputText } from "../../../../utils";

function SecretarProtocol({
  meetingId,
  agendas,
  isCompleted,
  checkProtocolStatus,
  memberRole,
                            meetingInfo,
    data
}) {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();
  const [protocolId, setProtocolId] = useState();
  const { t } = useTranslation();

  const { mutate: createProtocol, isLoading } = useMutation(
    async (answer) => {
      return await API.createProtocol({ answers: answer, meetingId });
    },
    {
      onSuccess: (data) => {
        checkProtocolStatus();
        setProtocolId(data.id);
      },
    },
    {
      onError: (error) => {
        console.log(error, "status");
      },
    }
  );
  const onSubmit = (data) => {
    let result = [];
    for (let key in data) {
      result.push({
        agendaId: +key,
        answer: editInputText(
          data[key]
            .replaceAll("<p>", '<p style="text-indent: 70px;padding: 0">')
            .replaceAll("&nbsp;", "")
        ),
      });
    }
    createProtocol(result);
  };

  const resetForm = () => {
    reset({ data: "" });
  };

  useEffect(() => {
    checkProtocolStatus();
  }, []);

  return (
    <StyledProtocol>
      {isCompleted ? (
        <UserProtocol
            meetingInfo={meetingInfo}
          checkProtocolStatus={checkProtocolStatus}
          agendas={agendas}
          meetingId={meetingId}
          isCompleted={isCompleted}
          protocolId={protocolId}
          memberRole={memberRole}
          data={data}
        />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <ProtocolsBody className="protos">
            {agendas?.map((item, index) => {
              return (
                <div className="editor" key={index}>
                  <Typography>
                    {t("userPage.protocol.title", { number: index + 1 })}
                  </Typography>
                  <Controller
                    key={index}
                    name={`${item?.id}`}
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                      <CKEditor
                        editor={ClassicEditor}
                        data={field.value}
                        config={{ removePlugins: ["Autoformat"] }}
                        onReady={(editor) => {
                          console.log("Editor is ready to use!", editor);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          field.onChange(data);
                        }}
                      />
                    )}
                  />
                </div>
              );
            })}
          </ProtocolsBody>
          <div className="actions">
            <MyButton
              background="#208667"
              color="#FFFFFF"
              weight="500"
              type="submit"
              backgroundHover="rgba(16, 139, 103, 0.906)"
            >
              {isLoading ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                  }}
                />
              ) : (
                "Format protocols"
              )}
            </MyButton>
            <Button sx={{ mx: 1 }} variant="outlined" onClick={resetForm}>
              {t("cancel")}
            </Button>
          </div>
        </form>
      )}
    </StyledProtocol>
  );
}

export default SecretarProtocol;
