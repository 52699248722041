import { EditBody, EditContainer } from "./style";
import MyButton from "../../../../components/UI/MyButton/MyButton";
import { Button } from "@mui/material";
import React, { useEffect, useRef, useMemo, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { t } from "i18next";
import { API } from "../../../../services/api";
import JoditEditor from "jodit-react";
import {toast} from "react-toastify";
import ReplaySharpIcon from "@mui/icons-material/ReplaySharp";

const ProtocolEdit = ({
  agendas,
  meetingId,
  setIsProtocol,
  lang,
  isProtocol,
  selectedLngs
}) => {
  const editor = useRef(null);
  const [data, setData] = useState("");
  console.log("data => " + data);

  const handleClick = () => {
    const save = async () => {
      if (isProtocol) {
        await API.saveProtocolHtml({ meetingId, language: lang, html: data })
          .then((res) => setIsProtocol(false))
          .catch((err) => console.log(err));
      } else {
        await API.saveProtocolQRHtml({ meetingId, language: lang, html: data })
          .then((res) => setIsProtocol(false))
          .catch((err) => console.log(err));
      }
    };
    save();
  };

  useEffect(() => {
    const fetchData = async () => {
      if (isProtocol) {
        await API.getProtocolHtml({ meetingId, lang })
          .then((res) => setData(res.data))
          .catch((err) => console.log(err));
      } else {
        await API.getProtocolQRHtml({ meetingId, lang })
          .then((res) => setData(res.data))
          .catch((err) => console.log(err));
      }
    };
    fetchData();
  }, [meetingId, lang]);


  const config = useMemo(() => ({
    readonly: false,
        placeholder: "Start typing ..."
  }), []);
    const resetProtocol = (lang) => {
        const reset = async () => {
            await API.resetPdfHtml({ meetingId, lang })
                .then((res) => toast.success(lang + " protocol reseted"))
                .catch((err) => console.log(err));
        };
        reset();
    };
    const resetQR = (lang) => {
        const reset = async () => {
            await API.resetQRHtml({ meetingId, lang })
                .then((res) => toast.success(lang + " protocol reseted"))
                .catch((err) => console.log(err));
        };
        reset();
    };
  return (
    <EditContainer>
      <EditBody>
        <JoditEditor
          ref={editor}
          value={data}
          config={config}
          tabIndex={1}
          onBlur={(newContent) => {
            setData(newContent);
          }}
        />
      </EditBody>


          <div style={{display: "flex", gap: "10px"}}>

              <MyButton
                  onClick={() => {
                      if (isProtocol)
                        resetProtocol(
                            selectedLngs.reduce((acc, item) => acc + "-" + item)
                        )
                      else
                        resetQR(
                            selectedLngs.reduce((acc, item) => acc + "-" + item)
                        )
                      setIsProtocol(false)
                  }}
                  className="reset_btn"
              >
                  <ReplaySharpIcon/>
                  <p className="hover_text">{t("revertOriginalVersion")}</p>
              </MyButton>
              <Button
                  sx={{
                      mx: 1,
                      borderRadius: 2,
                  }}
                  variant="outlined"
                  onClick={() => setIsProtocol(false)}
              >
                  {t("cancel")}
              </Button>
              <MyButton
                  width="210px"
                  background="#208667"
                  color="#fff"
                  onClick={handleClick}
              >
                  {t("userPage.protocol.protocolEditBtn")}
              </MyButton>
          </div>
      </EditContainer>
  );
};

export default ProtocolEdit;
